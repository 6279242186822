import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'servicesSeperator',
  pure: true
})
export class ServicesSeperatorPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let data = value.split(",").join(" |");
    return data;
  }

}
