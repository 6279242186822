import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Event as NavigationEvent, NavigationStart } from '@angular/router';
import { takeUntil } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { Destroy } from 'src/app/service/destroy';
import { StorageService } from 'src/app/service/storage.service';
import { navs } from 'src/app/utils/navigations';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
  viewProviders: [Destroy]
})
export class SideNavComponent implements OnInit {

  navItems: any = [];
  userData: any;
  currentUrl: string = '';
  constructor(private authService: AuthService, private storage: StorageService, public destroy$: Destroy, private router: Router, private route: ActivatedRoute){
   this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: NavigationEvent) => {
    if(event instanceof NavigationStart){
      this.currentUrl = event.url;
    }
   })
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.authService.getSideBar().pipe(takeUntil(this.destroy$)).subscribe((navs: navs) => {
      console.log(navs);
      this.navItems = [];
      this.navItems = navs;
    });

    this.storage.getItem('local', 'authData').pipe(takeUntil(this.destroy$)).subscribe((userData: any) => {
      this.userData = userData;
    })

  }

  routeTo(route: string){
    console.log(route);
    this.router.navigate([`${route}`]);
  }

}
