import { CanActivateFn, Router } from '@angular/router';
import { StorageService } from '../service/storage.service';
import { inject } from '@angular/core';

export const employeeGuard: CanActivateFn = () => {
  let storage = inject(StorageService);
  let router = inject(Router);
  const requiredDataKey = 'authData'; // Replace with the key for your authentication data
  let isAllowed: boolean = false;
   storage.getItem('local', requiredDataKey).subscribe((data) => {
    if(data.sub_type === 'COMPANY'){
      isAllowed = true;
    } else {
      isAllowed = false;
    }
  });
  !isAllowed ? router.navigate(['/']) : '';
  return isAllowed
};
