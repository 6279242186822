import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, delay, mergeMap, of, retry, retryWhen } from 'rxjs';
import { Router } from '@angular/router';
import { ToasterService } from '../service/toaster.service';
import { LoaderService } from '../service/loader.service';
import { StorageService } from '../service/storage.service';

export const maxRetries = 2;
export const delayMs = 2000;
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router:Router, private toastr:ToasterService, public loader: LoaderService, public storage: StorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((error) => {
        return error.pipe(
          mergeMap((error, index) => {
            this.toastr.error(error.error.message, 'Error');
            console.log(error);
            if (index < maxRetries && error.status == 500) {
              this.loader.setLoading(false);
              return of(error).pipe(delay(delayMs));
            }
            else if(error.status == 401) {
              this.storage.removeItem('local', 'authData');
              this.router.navigate(['/login']);
              this.loader.setLoading(false);
              return of(error);
            }
            this.loader.setLoading(false);
            throw error;
          })
        )}
      )
    )
  }
}
