<header>
    <div class="header-main">
        <div class="header-logo">
        <img src="../../../assets/images/renterz-logo.svg" alt="Renterz Logo" class="company-icon">
        </div>
        <!-- <div class="header-location">
            <div class="header-location-title">
                <img src="../../../assets/images/location-pin.svg" alt="location-icon" class="icon-location-pin">
                <span class="user-address-type">HOME</span>
            </div>
            <div class="user-location-container">
                <span>
                    Nagar road, kalyani nagar, pune...
                </span>
                <img src="../../../assets/images/header-location-down-arrow.svg" alt="location selection arrow">
            </div>
        </div> -->
    </div>

    <div class="header-signup">
        <!-- <span class="text-not-account">Don't have an account?</span> -->
        <!-- <button type="button" class="button-back" (click)="logout()"><img src="../../../assets/images/orange-arrow.svg"
                alt="orange colored arrow"
                style="margin-right: 5px;" /> Back to main website</button> -->

                <div class="custom-dropdown" *ngIf="isLoggedIn">
                    <button class="button-back" (click)="toggleDropdown()">
                      <img src="../../../assets/icons/sidebar/profile_active.svg" alt="user-icon"/>
                      {{ userName }}
                    </button>
                    <ul class="dropdown-menu" *ngIf="isOpen" [ngClass]="{'dropdown-below': isOpen}">
                      <li *ngFor="let option of dropdownOptions" (click)="selectOption(option.value)">
                        <i [class]="option.value"></i>
                        <span>{{ option.label }}</span>
                      </li>
                    </ul>
                  </div>
                  
    </div>
</header>