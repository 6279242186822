
<div class="accordion">
    <h5>FAQ's</h5>
    <div *ngFor="let item of faqs; let i = index" class="accordion-item" (click)="toggleItem(i)">
      <div class="accordion-question">
        {{ item.question }}
        <span class="accordion-icon" [ngClass]="{'rotate': expandedIndex == i}">
            <img src="../../../assets/icons/bottom-arrow.svg" />
        </span>
      </div>
      <div class="accordion-answer" *ngIf="expandedIndex == i">
        {{ item.answer }}
      </div>
    </div>
  </div>