<!-- <div id="slider">
  <figure>
  <img src="../../assets/images/dummy/a.png"  alt="a">
  <img src="../../assets/images/dummy/b.png" alt="b">
  <img src="../../assets/images/dummy/c.png"  alt="c">
  <img src="../../assets/images/dummy/d.png"  alt="d">
  </figure>
  </div> -->

  <div class="container">
    <div class="signup_wrapper row shadow p-3 mb-5 bg-white rounded">
      <div class="signup_left_content col-6">
          <p class="card-title">Welcome Back!</p>
          <p class="card-subtitle">Welcome back! Please enter your details.</p>
          <form class="custom-form">
            <label class="label-mobile">Mobile Number</label>
            <br />
            <div class="container-user-input-mobile">
              <div class="country-dropdown">
                <select id="country" name="country" title="Country">
                  <option value="IN">+91</option>
                  <option value="UAE" selected>+971</option>
                  <option value="UK">+44</option>
                </select>
                <img src="../../../assets/images/header-location-down-arrow.svg" alt="country-dropdown" class="dropdown-icon">
                <!-- <svg class="dropdown-icon" width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                              <path d="M5 10L10 15L15 10" stroke="#888" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg> -->
              </div>
              <div class="container-input">
                <input type="tel" placeholder="Enter your mobile number" class="input-mobile">
              </div>
            </div>
            <button type="button" class="button-get-otp"><span class="content">Get Verification Code</span></button>

            <div class="positon-relative">
            
              <span class="hr-or">OR</span>
              <hr class="hr">
            </div>
            <button type="button" class="button-social-login"><img src="../../../assets/images/google-logo.svg" alt="apple logo"
                height="20px" width="20px" class="social-login-logo"><span class="content">Sign up with Google</span></button>
            <button type="button" class="button-social-login"><img src="../../../assets/images/apple-logo.svg" alt="apple logo" class="social-login-logo"
                height="24px" width="20px"><span class="content">Sign up with Apple</span></button>
            <p class="t-c">By signing up, you agree to our <b>Terms & Conditions</b> and <b>Privacy Policy</b>
            </p>
          </form>
      </div>
      <div class="signup_right_content col-6">
        <!-- <div class="sliderContainer">
                        <div class="slider single-item">
                            <div class="item">
                                <img src="problems.png" />
                            </div>
                            <div class="item">
                                <img src="problems.png" />
                            </div>
                            <div class="item">
                                <img src="problems.png" />
                            </div>
                        </div>
                        <div class="progressBarContainer">
                          <div class="item">
                            <h3>Slide 1</h3>
                            <span data-slick-index="0" class="progressBar"></span>
                          </div>
                          <div class="item">
                            <h3>Slide 2</h3>
                            <span data-slick-index="1" class="progressBar"></span>
                          </div>
                          <div class="item">
                            <h3>Slide 3</h3>
                            <span data-slick-index="2" class="progressBar"></span>
                          </div>
                        </div>
                      </div> -->
        <div id="slider">
          <figure>
            <img src="../../../assets/images/slider-image-1.svg" alt="slider-1">
            <img src="../../../assets/images/slider-image-1.svg" alt="slider-2">
            <img src="../../../assets/images/slider-image-1.svg" alt="slider-3">
            <img src="../../../assets/images/slider-image-1.svg" alt="slider-4">
          </figure>
        </div>
      </div>
    </div>
  </div>

  