import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { Destroy } from 'src/app/service/destroy';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css'],
  standalone: true,
  imports: [CommonModule],
  providers: [Destroy]
})
export class FaqsComponent {
  @ViewChild("el", {read: ElementRef}) el!: ElementRef;

  faqs: any;
  expandedIndex: number | undefined;
  constructor(private commonService: CommonService, private destroy$: Destroy) {
    this.getFaqs();
  }

  getFaqs() {
    this.commonService.getFaqs("1", "REQUEST").pipe(takeUntil(this.destroy$)).subscribe((data:any) => {
      this.faqs = data.data;
    })
  }

  
  toggleItem(index: any) {
    this.expandedIndex = this.expandedIndex === index ?  undefined :  index;
}
}
