import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotationTypePipe } from './quotation-type.pipe';
import { ServicesSeperatorPipe } from './services-seperator.pipe';



@NgModule({
  declarations: [
    QuotationTypePipe,
    ServicesSeperatorPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [QuotationTypePipe, ServicesSeperatorPipe]
})
export class PipeModule { }
